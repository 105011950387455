@import url("./button.css");
@import url("./carousel.css");
@import url("./checkbox.css");
@import url("./grid.css");
@import url("./modals.css");
@import url("./steps.css");
@import url("./table.css");
@import url("./toggle.css");

:root {
  --rs-primary-50: var(--color-primary-50);
  --rs-primary-100: var(--color-primary-100);
  --rs-primary-200: var(--color-primary-200);
  --rs-primary-300: var(--color-primary-300);
  --rs-primary-400: var(--color-primary-400);
  --rs-primary-500: var(--color-primary-500);
  --rs-primary-600: var(--color-primary-600);
  --rs-primary-700: var(--color-primary-700);
  --rs-primary-800: var(--color-primary-800);
  --rs-primary-900: var(--color-primary-900);

  --rs-gray-0: var(--color-gray-0);
  --rs-gray-50: var(--color-gray-50);
  --rs-gray-100: var(--color-gray-100);
  --rs-gray-200: var(--color-gray-200);
  --rs-gray-300: var(--color-gray-300);
  --rs-gray-400: var(--color-gray-400);
  --rs-gray-500: var(--color-gray-500);
  --rs-gray-600: var(--color-gray-600);
  --rs-gray-700: var(--color-gray-700);
  --rs-gray-800: var(--color-gray-800);
  --rs-gray-900: var(--color-gray-900);

  --rs-input-bg: var(--color-white);

  --rs-divider-border: var(--color-gray-400);
}

.rs-loader-backdrop {
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.3);
}

.rs-table-cell-header .rs-table-cell-content {
  background-color: var(--color-gray-200);
}

.rs-nav-bar {
  border-top-color: var(--color-gray-400) !important;
}

.drawer-secondary-bg .rs-drawer-content {
  background-color: var(--color-white) !important;
}

.rs-slider-mark {
  top: 7px;
}

.skeleton p {
  width: 100% !important;
}

.phone-number-select > div.rs-picker-toggle.rs-btn.rs-btn-default {
  padding-left: 16px;
  padding-right: 16px;
}

.number-input-no-step
  span.rs-input-number-btn-group-vertical
  .rs-input-number-touchspin-up,
.number-input-no-step
  span.rs-input-number-btn-group-vertical
  .rs-input-number-touchspin-down,
.number-input-no-step span.rs-input-number-btn-group-vertical {
  /* visibility: hidden; */
  display: none;
}

.placeholder-darker {
  /* --rs-placeholder: var(--rs-gray-300); */
  --rs-placeholder: red !important;
  /* --rs-placeholder-active: var(--rs-primary-500); */
  --rs-placeholder-active: blue !important;
}

.placeholder-no_margin .rs-placeholder-row {
  margin: 0 !important;
}

.rs-notification-content {
  max-width: fit-content;
}

.rs-picker-popup-daterange {
  z-index: 10;
}
