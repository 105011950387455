.create-role-modal .rs-checkbox-control {
  top: 3px !important;
  left: 4px !important;
}

.create-role-modal .rs-checkbox-checker {
  min-height: 24px !important;
  padding-left: 24px !important;
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}

/* .permissions-checkboxes .rs-checkbox-control {
  top: 0px !important;
  left: 0px !important;
}
.permissions-checkboxes input {
  min-height: 16px;
  min-width: 16px;
  height: 16px;
  width: 16px;
}

.permissions-checkboxes .rs-checkbox-checker {
  min-height: 16px !important;
  min-width: 16px !important;
  height: 16px;
  width: 16px;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
} */
