.navlink {
  text-decoration: none !important;
}

.active-link > div {
  background-color: var(--color-primary) !important;
}

.navlink-content {
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.navlink-content-expanded {
  padding-left: 24px;
}
/* .navlink-content-not-expanded {
} */
.navlink-content-child-expanded {
  padding-left: 40px;
}
/* .navlink-content-child-not-expanded {
} */

.navlink-content p:hover,
.navlink-content p:visited {
  text-decoration: none;
}

.navlink-content:hover {
  background-color: var(--color-primary-900) !important;
}
.app {
  height: 100vh;
}

.main {
  height: 100%;
}

.sidemenu {
  overflow: auto;
  height: 100%;
  width: 255px;
  position: fixed;
  z-index: 1;
}
.sidemenu-expanded {
  width: 255px;
}
.sidemenu-not-expanded {
  width: 88px;
}

.sidemenu-toggle-button:hover {
  background-color: var(--color-primary-900);
}
.sidemenu-toggle-button:focus {
  background-color: var(--color-primary-900);
}

.expand-submenu-button {
  border-radius: 100%;
}
.expand-submenu-button:hover {
  background-color: var(--color-gray-800);
}

.expandable-menu-item-arrow:hover {
  background-color: var(--color-gray-500);
}

.mobile-navlink {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-navlink > button,
.mobile-nav-other > button {
  --rs-btn-subtle-active-bg: var(--color-secondary);
  --rs-btn-subtle-hover-bg: var(--color-secondary);
}

.mobile-navlink-active svg {
  fill: var(--color-primary) !important;
}
