.button-gray {
  --rs-btn-default-hover-bg: var(--color-gray-500);
  --rs-btn-default-bg: var(--color-gray-400);
  --rs-btn-default-disabled-bg: var(--color-gray-200);

  color: var(--color-white) !important;
  font-family: Inter;
  font-style: normal;
}

.rs-btn {
  font-family: Inter;
  font-style: normal;
}
