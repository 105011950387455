.settings-panel {
  background-color: var(--color-white);
  padding: 16px !important;
  border-radius: 8px !important;
}

.settings-item {
  padding-left: 16px;
}

.settings-item--not-last {
  border-bottom: 1px solid var(--color-gray-200);
}

.settings-item {
  height: 50px;
}

.settings-item-text {
  padding-right: 10px;
}
