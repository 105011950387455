.rs-table-row {
  z-index: -1;
}

.rs-table-row.rs-table-row-rowspan {
  z-index: 0;
}

.rs-table-row-header {
  background-color: var(--color-gray-200) !important;
}
