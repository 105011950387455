.bg-white {
  background-color: var(--color-white);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.hover-gray-100:hover {
  background-color: var(--color-gray-100) !important;
}
.hover-gray-200:hover {
  background-color: var(--color-gray-200) !important;
}
.hover-gray-300:hover {
  background-color: var(--color-gray-300) !important;
}
.hover-darken-white-bg:hover {
  background-color: var(--color-light_white) !important;
}
